<template>
    <div class="page-table mainDiv haberTanim mb-30" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">
            {{$t("src.views.apps.popup.tanimlama.title")}}
            
            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" size="mini" type="text" icon="el-icon-refresh" class="mr-5"></el-button>
                </el-tooltip>
            </div>
            
        </div>
        <el-row :gutter="10" v-loading="loading"
            :element-loading-text='$t("src.views.apps.popup.tanimlama.addLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="popupForm" :rules="rulesPopup" ref="popupForm" class="demo-ruleForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">
                    <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft">
                        <div>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.popup.tanimlama.baslik")' prop="baslik">:
                                        <el-input size="small" v-model="popupForm.baslik" :placeholder='$t("src.views.apps.popup.tanimlama.baslikPlace")'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.popup.tanimlama.link")' prop="link">:
                                        <el-select v-model="popupForm.link" placeholder="Link Seçiniz" size="mini" style="width: 100%">
                                            <div v-loading="seoLinkLoading"
                                            :element-loading-text='$t("src.views.apps.popup.tanimlama.linkListLoading")'
                                            element-loading-spinner="el-icon-loading"
                                            element-loading-background="rgba(255, 255, 255, 1)">
                                                <el-option
                                                v-for="(item, index) in seoLinkList"
                                                :key="index"
                                                :label="item.link"
                                                :value="item.link">
                                                </el-option>
                                            </div>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.popup.tanimlama.tarih")' prop="tarih">:
                                        <el-date-picker
                                            size="small"
                                            style="width: 100%"
                                            v-model="popupForm.tarih"
                                            type="datetimerange"
                                            start-placeholder="Başlangıç Tarihi"
                                            end-placeholder="Bitiş Tarihi"
                                            format="yyyy-MM-dd HH:mm:ss"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            :default-time="['08:00:00', '17:00:00']">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.popup.tanimlama.icerik")' prop="icerik">:
                                        <ckeditor :editor="editor" :config="editorConfig" v-model="popupForm.icerik"></ckeditor>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                    <el-collapse class="animated fadeInUp" style="margin-top: 10px" v-model="activeCollapse">
                        <draggable data-source="juju" class="list-group" group="a">
                            <el-collapse-item class="card-shadow--medium" title="Resim Ayarları" name="4">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.sayfa.tanimlama.resim") + " (800x600)"' :prop="popupForm.image.length > 0 ? '' : 'resim'">:
                                            <el-upload
                                            id="popekle-resim"
                                            class="resimler"
                                            action=""
                                            list-type="picture-card"
                                            :file-list="imageListMain"
                                            :auto-upload="false"
                                            accept=".jpg,.jpeg,.png"
                                            :on-change="imageUpload"
                                            :on-remove="imageRemove">
                                                <i slot="default" class="el-icon-plus"></i>
                                            </el-upload>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>
                        </draggable>
                    </el-collapse>
                </el-col>
                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" name="1" :title='$t("src.views.apps.popup.tanimlama.durum")'>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item prop="durum">
                                        <el-select size="small" v-model="popupForm.durum" style="width: 100%">
                                            <el-option value="0" :label='$t("src.views.apps.popup.tanimlama.taslagaAt")'></el-option>
                                            <el-option value="1" :label='$t("src.views.apps.popup.tanimlama.yayinla")'></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-button size="mini" v-on:click="popupTanimlama('popupForm',popupForm.durum)" class="onayBtn" type="primary">
                                    {{$t('src.views.apps.popup.tanimlama.popupEkle')}}
                                </el-button>
                                &nbsp;
                                <el-popover placement="bottom" width="300" v-model="visibleSil">
                                    <p>{{$t('src.views.apps.messages.clearFormAlert')}}</p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="primary" @click="resetForm('popupForm')">
                                            {{$t('src.views.apps.genel.yes')}}
                                        </el-button>
                                        <el-button size="mini" @click="visibleSil = false;">
                                            {{$t('src.views.apps.genel.no')}}
                                        </el-button>
                                    </div>
                                    <el-button size="mini" slot="reference">
                                        {{$t('src.views.apps.genel.temizle')}}
                                    </el-button>
                                </el-popover>
                                <el-tooltip :content='$t("src.views.apps.popup.tanimlama.copeAt")' :open-delay="500" placement="bottom">
                                    <el-button class="cop" v-on:click="popupTanimlama('popupForm','0')" type="text" icon="el-icon-delete"></el-button>
                                </el-tooltip>
                            </el-row>
                        </el-collapse-item>
                        <draggable class="list-group" group="a">
                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.popup.tanimlama.gorunum")' name="2">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item prop="gorunum">
                                            <el-switch
                                                v-model="popupForm.gorunum"
                                                active-value="1"
                                                inactive-value="0"
                                                :active-text='$t("src.views.apps.popup.tanimlama.tumSayfalar")'
                                                :inactive-text='$t("src.views.apps.popup.tanimlama.anaSayfa")'>
                                            </el-switch>
                                        </el-form-item>
                                        <div style="font-style: italic">
                                            {{$t("src.views.apps.popup.tanimlama.gorunumAciklama")}}
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>
                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.popup.tanimlama.gosterim")' name="3">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item prop="otomatik">
                                            <el-switch
                                                v-model="popupForm.otomatik"
                                                active-value="1"
                                                inactive-value="0"
                                                :active-text='$t("src.views.apps.popup.tanimlama.otomatik")'
                                                :inactive-text='$t("src.views.apps.popup.tanimlama.manuel")'>
                                            </el-switch>
                                        </el-form-item>
                                        <div style="font-style: italic">
                                            {{$t("src.views.apps.popup.tanimlama.otomatikAciklama")}}
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>
                        </draggable>
                    </el-collapse>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>
<script>
    import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
    import EventBus from '@/components/event-bus'
    import Resim from '@/components/Resim.vue'
    import draggable from 'vuedraggable'
    import functions from "../../../functions.js"
    import JQuery from 'jquery';

    let $ = JQuery;
    import popupService from '../../../WSProvider/PopService'
    import seoService from "../../../WSProvider/SeoService"
    import notification from '../../../notification'

    export default {
        name: "PopupTanimla",
        components: {
            Resim,
            draggable,
        },
        mounted() {
            this.getSeoLink();
        },
        data() {
            return {
                seoLinkList: [],
                seoLinkLoading : false,

                loading: false,
                imageListMain: [],
                editor: ClassicEditor,
                editorConfig: {
                    language: 'tr'
                },
                visibleSil: false,
                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                popupForm: {
                    baslik: '',
                    icerik: '',
                    image: [],
                    link: '',
                    tarih: '',
                    durum: '1',
                    gorunum: '0',
                    otomatik: '1',
                },
                rulesPopup: {
                    baslik: [{
                        required: true,
                        message: "Lütfen Başlık Giriniz",
                        trigger: 'blur'
                    }],
                    tarih: [{
                        required: true,
                        message: "Lütfen Tarih Seçiniz",
                        trigger: 'blur'
                    }],
                    resim: [{
                        required: true,
                        message: "Lütfen Resim Seçiniz",
                        trigger: ['blur', 'trigger']
                    }],
                },
            }
        },

        methods: {
            refreshPage(){
                this.loading = false;
                this.getSeoLink();
            },

            imageUpload(file, fileList) {
                $('#popekle-resim .el-upload--picture-card').hide();
                this.imageListMain = []
                this.popupForm.image=[]
                if ((file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg' || file.raw["type"] === 'image/png') && this.imageListMain.length < 2) {
                    var item = {
                        name: file.name,
                        url: file.url,
                        id: file.raw.lastModified,
                        size: file.raw.size
                    };
                    this.imageListMain.push(item)
                    file.raw["tmp_name"] = file.url;
                    
                    this.popupForm.image.push(file.raw)

                    fileList.pop();
                }
            },

            imageRemove(file) {
                $('#popekle-resim .el-upload--picture-card').show();
                this.imageListMain.splice(this.imageListMain.indexOf(file), 1);
                this.popupForm.image = [];
            },

            getSeoLink() {
                try{
                    this.seoLinkLoading = true;
                    seoService.seoList(1).then(response => {
                        if (response.status === 200) {
                            localStorage.setItem("userDataDemirag", response.token)
                            this.seoLinkList = response.data.data;
                        }
                        this.seoLinkLoading = false;
                    }).catch(error => {
                        if(err.responseJSON){
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else{
                                notification.Status(250, this);
                            }
                        }
                        this.seoLinkLoading = false;
                    })
                }catch(e){
                    this.seoLinkLoading = false;
                }
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.visibleSil = false;
            },
            
            popupTanimlama(formName, durum) {
                if(this.validateForm(formName)){
                    this.openConfirmDialog("Pop-up tanımlamak istediğinize emin misiniz?").then(() => {
                            try{
                                this.loading = true;
                                let baslamaTarihi, bitisTarihi;
                                if (this.popupForm.tarih) {
                                    baslamaTarihi = this.popupForm.tarih[0];
                                    bitisTarihi = this.popupForm.tarih[1];
                                }
                                //baslik, icerik, resim, link, otomatik, baslamaTarihi, bitisTarihi, gorunum, durum
                                popupService.popTanimlama(this.popupForm.link, this.popupForm.baslik, (this.popupForm.icerik), this.popupForm.image[0], this.popupForm.otomatik, baslamaTarihi, bitisTarihi, this.popupForm.gorunum, durum).then(response => {
                                    if(response.status == 200){
                                        localStorage.setItem("userDataDemirag", response.token)
                                        this.resetForm(formName);
                                        EventBus.$emit("popList", true)
                                        notification.Status('success', this, response.msg);
                                        this.sayfaKapat('pop-uptanimla')
                                    }
                                    this.loading = false;
                                }).catch(err => {
                                    if(err.responseJSON){
                                        let error = err.responseJSON
                                        if(error.status == 401){
                                            notification.Status(150, this);
                                        }else if(error.status == 404){
                                            this.popupListe = [];
                                            this.popupListeClone = [];
                                        }
                                        else notification.Status("errorMsg", this, error.errMsg)
                                    }else{
                                        //notification.Status(503, this)
                                    }
                                    this.loading = false;
                                })
                            }catch(e){
                                this.loading = false;
                            }
                        })
                }

            },
        }
    }
</script>

<style scoped>
    .upload-demo ul {
        margin: 0;
        padding: 0;
        list-style: none;
        background: aliceblue;
        width: 50%;
    }

    .cop {
        color: #ec205f;
        position: relative;
        float: right;
        right: 15px;
        bottom: 10px;
        font-size: 20px;
    }


    .el-tag + .el-tag {
        margin-left: 10px;
    }

</style>