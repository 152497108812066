import WsProvider from './provider/WSProvider'

var serviceName = "webservis";
var moduleName = "pop";

var popService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    // $data['baslik'],$data['icerik'], $upload['dosyaAdi'], $data['link'],$data['otomatik'],$data['baslamaTarihi'],$data['bitisTarihi'],$data['gorunum'], $data["durum"], $data['enBaslik'],$data['enIcerik'],
    popTanimlama(link, baslik, icerik, resim, otomatik, baslamaTarihi, bitisTarihi, gorunum, durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            link,
            baslik: baslik,
            icerik: icerik,
            resim: resim,
            otomatik: otomatik,
            baslamaTarihi: baslamaTarihi,
            bitisTarihi: bitisTarihi,
            gorunum: gorunum,
            durum: durum,
            serviceName: moduleName,
            methodName: "popEkle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },
    popListDetay(baslangic, limit, durum) {},

    popupListe(durum, baslangic, limit) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "popListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },
    popupSayisi(durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            durum: durum,
            serviceName: moduleName,
            methodName: "popSayisi"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },


    popDetay(popID) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            popID: popID,
            serviceName: moduleName,
            methodName: "popDetay"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    popDurumGuncelle(popID, durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            popID,
            durum,
            serviceName: moduleName,
            methodName: "popAktifPasifEt"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    popPasifYap(popID) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            popID: popID,
            durum: 0,
            serviceName: moduleName,
            methodName: "popAktifPasifEt"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    popAktifYap(popID, ) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            popID: popID,
            durum: 1,
            serviceName: moduleName,
            methodName: "popAktifPasifEt"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },


    //$data['popID'], $data['baslik'],$data['icerik'], $upload['dosyaAdi'], $data['link'],$data['otomatik'],$data['baslamaTarihi'],$data['bitisTarihi'],$data['gorunum'], $data["durum"], $data['enBaslik'],$data['enIcerik'],
    popGuncelle(durum, popID, link, baslik, icerik, resim, otomatik, baslamaTarihi, bitisTarihi, gorunum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            popID: popID,
            baslik: baslik,
            icerik: icerik,
            resim: resim,
            link: link,
            otomatik: parseInt(otomatik),
            baslamaTarihi: baslamaTarihi,
            bitisTarihi: bitisTarihi,
            gorunum: parseInt(gorunum),
            durum: parseInt(durum),
            serviceName: moduleName,
            methodName: "popGuncelle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    }
}

export default popService